import React, { useCallback, useEffect, useMemo } from "react"

import { hooks } from "@front/volcanion"
import { HookUtils } from "@front/volcanion/utils"
import { Loader } from "@front/squirtle"

import Callbacks from "./callbacks"
import NoResultComponent from "../noResult"

const withContainer = Component => props => {
  const colors = hooks.useRelayConfig('COLORS')

  const [{ company }, setFormState] = hooks.useFormState()
  const [contract, service, user, date_start, date_end, schedule_type] = hooks.useFieldValues(['contract', 'service', 'user', 'date_start', 'date_end', 'schedule_type'])

  const [data, search, state] = hooks.useModelValues('order', 'get', {})
  const [dataExpanse, searchExpanse] = hooks.useModelValues('order', 'get', {})

  const getChartData = useCallback(Callbacks.getChartDataHandler(data, colors, schedule_type), [data, colors, schedule_type])


  const { labels, datasets, total } = useMemo(getChartData, [schedule_type, colors, data])
  const totalExpanse = _.round(_.sumBy(dataExpanse, 'total_payment'))

  const default_filter = {
    requestedAt: { '>=': date_start, '<=': date_end },
    service: (!!service && { companyservice_id: service }) || { contract: (!!contract && { companycontract_id: contract }) || { company } },
    do: user || undefined,
    schedule_type: schedule_type || undefined
  }
  const filter = {
    or: [
      _.merge({}, default_filter, { reason: 'not_served' }),
      _.merge({}, default_filter, { status: { '!': 'canceled' } }),
      _.merge({}, default_filter, { reason: { '!': 'not_served' }, status: 'canceled' })
    ]
  }

  useEffect(() => {
    setFormState({ totalExpanse })
  }, [totalExpanse])

  useEffect(() => {
    search({
      filter,
      options: {
        group_by: ['status AS status', 'reason AS reason', 'schedule_type AS schedule_type'],
        no_select: true,
        query_operations: [{ path: 'order_id', operation: 'COUNT' }],
        sort: ['schedule_type DESC'],
        select: [],
        limit: -1,
        type: 'b2b'
      }
    })
    searchExpanse({
      filter,
      options: {
        no_select: true,
        query_operations: [{ path: 'assigned_transport.payment.total', alias: 'total_payment', operation: 'SUM' }],
        select: [],
        limit: -1,
        type: 'b2b'
      }
    })
  }, [contract, service, user, date_start, date_end, schedule_type])

  const mergedProps = {
    labels,
    datasets,
    total,
    schedule_type
  }

  return (
    <Loader isLoading={HookUtils.getLoadingState([state])} size={100} >
      {_.isEmpty(data) ? <NoResultComponent /> : <Component {...mergedProps} />}
    </Loader>
  )
}

export default withContainer
