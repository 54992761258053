import moment from 'moment'

class Callbacks {
  static afterDateChangeHandler(setFormChange, input_date, type) {
    return function afterDateChange(value) {
      const date_start = moment(type === 'start' ? value : input_date)
      const date_end = moment(type === 'end' ? value : input_date)
      const daysDifference = date_end.diff(date_start, 'days')
      setFormChange([{ field: 'unit', value: daysDifference > 730 ? 'year' : 'day' }])
    }
  }

  static afterPeriodChangeHandler(setFormChange) {
    return function afterPeriodChange(value) {

      switch (value) {
        case 'current_month':
          setFormChange([
            { field: 'date_start', value: moment().startOf('month').format() },
            { field: 'date_end', value: moment().format() }
          ])
          break
        case 'last_month':
          setFormChange([
            { field: 'date_start', value: moment().subtract(1, 'month').startOf('month').format() },
            { field: 'date_end', value: moment().subtract(1, 'month').endOf('month').format() }
          ])
          break
        case 'last_thirty_days':
          setFormChange([
            { field: 'date_start', value: moment().subtract(30, 'days').format() },
            { field: 'date_end', value: moment().format() }])
          break
        case 'last_ninety_days':
          setFormChange([
            { field: 'date_start', value: moment().subtract(90, 'days').format() },
            { field: 'date_end', value: moment().format() }])
          break
        case 'start_of_year':
          setFormChange([
            { field: 'date_start', value: moment().startOf('year').format() },
            { field: 'date_end', value: moment().format() }
          ])
          break
        case 'last_year':
          setFormChange([
            { field: 'date_start', value: moment().subtract(1, 'year').startOf('year').format() },
            { field: 'date_end', value: moment().subtract(1, 'year').endOf('year').format() }
          ])
          break
        default:
          break
      }
    }
  }
}

export default Callbacks

