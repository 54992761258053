import React from 'react'

import { hooks } from '@front/volcanion'

import moment from 'moment'

import Chart from '../../Chart'

const hexToRgba = (hex, alpha = 1) => {
  const replacedHex = hex.replace(/^#/, '')
  const formatted_hex = replacedHex?.length === 3
    ? _.map(_.split(replacedHex, ''), char => char + char).join('')
    : replacedHex

  const r = parseInt(formatted_hex.slice(0, 2), 16)
  const g = parseInt(formatted_hex.slice(2, 4), 16)
  const b = parseInt(formatted_hex.slice(4, 6), 16)

  return `rgba(${r},${g},${b},${alpha})`
}

const UsersCountChart = ({ data, date_start, date_end }) => {
  const colors = hooks.useRelayConfig('COLORS')

  const datasets = [{
    data,
    borderColor: colors?.primary?.[700],
    borderWidth: .5,
    fill: true,
    tension: .4,
    borderDash: [3, 3],
    pointRadius: 0,
    cumulative: true,
    backgroundColor: function (context) {
      const chart = context.chart
      const { ctx, chartArea } = chart

      if (!chartArea) return null

      const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)

      gradient.addColorStop(0, hexToRgba(colors?.primary?.[700], 0))
      gradient.addColorStop(.25, hexToRgba(colors?.primary?.[700], .1))
      gradient.addColorStop(.5, hexToRgba(colors?.primary?.[700], .2))
      gradient.addColorStop(.75, hexToRgba(colors?.primary?.[700], .3))
      gradient.addColorStop(1, hexToRgba(colors?.primary?.[700], .6))

      return gradient
    }
  }]

  const maxValue = _.maxBy(data, 'y')?.y || 0
  const minValue = _.minBy(data, 'y')?.y || 0
  const valueRange = maxValue - minValue
  const margin = valueRange * 0.1
  const adjustedMin = _.max([Math.ceil(minValue - margin), 0])
  const adjustedMax = _.max([Math.ceil(maxValue + margin), 0])
  const stepSize = Math.ceil(valueRange / 5)
  const roundedStepSize = Math.ceil(stepSize / 10) * 10

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: { unit: 'month' },
        grid: { display: false },
        min: moment(date_start).format('YYYY-MM-DD'),
        max: moment(date_end).format('YYYY-MM-DD')
      },
      y: {
        beginAtZero: false,
        stacked: true,
        min: adjustedMin,
        max: adjustedMax,
        ticks: {
          stepSize: roundedStepSize
        }
      }
    },
    plugins: {
      legend: { display: false }
    }
  }

  return (
    <Chart
      config={{
        type: 'line',
        data: { datasets: datasets },
        options
      }}
      style={{ width: '100%', height: '280px' }}
      watchers={[data]}
    />
  )
}

export default UsersCountChart
